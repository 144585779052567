<template>
  <div class="default-container">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.default-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: $default-layout-py 40px $default-layout-py 24px;
  width: calc(100% - #{$sidebar-width});
  box-shadow: -8px 4px 33.3px 0px rgba(0, 0, 0, 0.08);
  margin-left: $sidebar-width;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1023px) {
    width: 100%;
    margin-left: 0;
    padding: 56px 0 0 0 !important;
  }

  @media screen and (min-width: 1920px) {
    width: calc(100% - #{$sidebar-width-big});
    margin-left: $sidebar-width-big;
  }

  &:has(#tours-driver) {
    padding-bottom: 100px;
  }

  &:has(.email-verification-banner) {
    padding-top: calc(#{$default-email-verification-height} + 16px);
  }
}
</style>
